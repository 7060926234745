<!--登录页-->
<!--登录页-->

<style  scoped>
.content {
  background: #fff;
  line-height: 36px;
  width: 1300px;
  text-align: left;
  margin: 20px auto;
}
h1 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

h2 {
  font-size: 14px;
  font-weight: bold;
}
h3{
  text-indent: 2em;
  font-weight: bold;
  font-size: 14px;
}
p{
   text-indent: 2em;
}
h4{
   /* text-indent: 2em;   */
   font-weight: normal;
   margin:5px 0;
}
</style>


<template>
  <div class="content">
    <h1>四方云FBT服务平台隐私政策</h1>
    <p style="text-indent:2em;">
      【特别提示】四方云FBT服务平台非常重视用户的隐私和个人信息保护。您在使用四方云FBT服务平台的产品与服务时，我们可能会收集和使用您的相关信息。我们希望通过本隐私政策向您说明我们在您使用我们的产品、服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
    </p>
     <p style="text-indent:2em;">
      请您在使用四方云FBT服务平台的产品及服务前，仔细阅读并充分理解本隐私政策，重点内容我们已经用粗体标识，请您特别关注。在阅读过程中，如果您不同意相关政策或其中任何条款约定，您应立即停止使用四方云FBT服务平台的产品及服务。您在点击“同意”按钮后，本隐私政策即构成对双方有约束力的法律文件，即表示您同意我们按照本隐私政策收集、使用、处理和存储您的相关信息。如果您对本隐私政策有任何疑问、意见或建议，可通过本隐私政策提供的联系方式与我们联系。
    </p>

    <h2>一、适用范围</h2>
    <p style="text-indent:2em;">
      1、本隐私政策适用于四方云FBT服务平台的运营主体，具体是指北京传世博润科技有限公司（以下称“我们”）。前述四方云FBT服务平台产品和服务的使用人在本隐私政策中称为“您”。
    </p>
    <p style="text-indent:2em;">
      2、我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，保护您的个人信息安全。
    </p>

    <h2>二、隐私政策的主要内容</h2>
    <p>1、我们如何收集和使用您的信息</p>
    <p>2、Cookie和类似技术的使用</p>
    <p>3、我们如何共享、转让、公开披露您的信息</p>
    <p>4、我们如何存储和保护您的信息</p>
    <p>5、您如何管理您的信息</p>
    <p>6、本隐私政策的适用及更新</p>
    <p>7、如何联系我们</p>

    <h2>三、隐私政策的具体内容</h2>
    <h3>1、我们如何收集和使用您的信息</h3>
    <p style="text-indent:2em;">
      个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：个人基本资料（个人姓名、生日、性别、民族、国籍、家庭关系、住址、个人电话号码、电子邮箱地址）；个人身份信息（身份证信息）；个人生物识别信息（面部特征）；网络身份标识信息（个人信息主体账号、IP地址、个人数字证书及与前述有关的密码、口令、口令保护答案）；个人财产信息（银行账户）；个人通讯信息（通讯录）；个人常用设备信息（硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（android ID/IDFA））；个人位置信息（行程信息、近似定位信息、精准定位信息、经纬度）。
    </p>

    <p style="text-indent:2em;">
      个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息。本隐私政策中涉及的个人敏感信息包括：个人身份信息（身份证）；网络身份标识信息（个人信息主体账号、IP地址、个人数字证书及与前述有关的密码、口令、口令保护答案）；个人财产信息（银行账户）；个人通讯信息（通讯录）；个人上网记录（软件使用记录、点击记录、收藏列表）；个人位置信息（行程信息、精准定位信息、经纬度）；其他信息等。
    </p>

    <p style="text-indent:2em;">
      为了向您提供更好的服务，我们根据您所使用服务的不同，收集您与服务相关的不同信息。
    </p>
    <p>（一）依据法律法规及监管规定履行法定义务</p>
    <p style="text-indent:2em;">
      针对各项服务，我们需要按照法律法规及监管规定履行相应的法定义务，包括资质审核、采取风险防范措施。当您需要使用四方云FBT服务平台服务时，为了遵守法律法规对交易资质的要求，对您的身份进行识别，四方云FBT服务平台可能会收集您的身份信息、联系方式、账户及认证信息。同时，为了验证您提供信息的准确性和完整性，我们可能会与合法留存您的信息的国家机关、金融机构、企事业单位进行核对。如果您不提供前述信息，将无法使用与监管要求相关的部分服务，但不影响您使用我们提供的其他服务。
    </p>
    <p>（二）为您提供产品或服务</p>
    <p>1）注册、登陆</p>
    <p style="text-indent:2em;">
      当您在四方云FBT服务平台创建账户进行注册时，您必须向我们提供您的基本信息（手机号码），并设置登录密码。如果您不提供前述信息，则您无法注册/登录四方云FBT服务平台账户，且无法使用需注册/登录的功能，但不会影响您正常使用无需注册/登录的功能。
    </p>
    <p>2）浏览</p>
    <p style="text-indent:2em;">
      您可在四方云FBT服务平台上浏览轮播图、活动药品。为此，我们可能会收集您使用四方云FBT服务平台时的设备信息，包括设备名称、设备型号、唯一设备标识符（android ID/IDFA /GUID/UUID、SIM卡IMSI信息）、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）、浏览器类型以此来为您提供信息展示的最优方案。此外，在您使用浏览功能的过程中，我们会自动收集您使用四方云FBT服务平台的详细情况，并作为有关的网络日志保存，包括您输入的搜索关键词信息和点击的链接，您浏览的内容及评论信息，访问的日期和时间，以及您请求的网页记录、操作系统、软件版本号、IP信息。如果您不提供前述信息，则您无法使用浏览功能。
    </p>
    <p>3）搜索功能</p>
    <p style="text-indent:2em;">
      当您使用四方云FBT服务平台提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用四方云FBT服务平台服务时所浏览的或要求的其他信息和内容详情。为了给您带来更便捷的搜索服务并不断完善四方云FBT服务平台的产品和服务，我们可能会使用浏览器网络存储机制和应用数据缓存，您搜索的信息我们可能进行本地存储。该等关键词信息通常无法单独识别您的个人身份，不在本隐私政策的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
    </p>
    <p>（三）安全运行</p>
    <p style="text-indent:2em;">
      1）为了保障软件与服务的安全运行，我们会收集您的软件版本号服务日志、手机状态信息。
    </p>
    <p style="text-indent:2em;">2）如果您不提供上述信息，您将无法享受我们提供的产品与/或服务。</p>
    <p>（四）您可选择是否授权我们收集和使用您的个人信息的情形</p>
    <p style="text-indent:2em;">
      为提升您的服务体验及改进服务质量，或者为您推荐更优质或更适合的服务，四方云FBT服务平台会向您申请下列与个人信息相关的系统权限：
    </p>
    <p style="text-indent:2em;">
      1）基于位置信息的个性化推荐服务：我们会在您开启位置权限后访问获取您的位置信息，根据您的位置信息为您提供更契合您需求的页面展示、产品或服务；
    </p>
    <p style="text-indent:2em;">
      2）基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行扫码用于拍摄照片用于上传系统管理功能的需要，包含且不限于如电子回单、客户拜访、商品陈列等。
    </p>
    <p style="text-indent:2em;">
      3）基于详细文件的访问、上传及存储的附加服务：您可在开启对应文件的权限后使用该功能上传您的照片/图片，以实现上传系统管理功能的需要，包含且不限于如电子回单、客户拜访、商品陈列等的功能；我们可能会存储您所上传的照片/图片来符合系统管理功能的需要。
    </p>
    <p style="text-indent:2em;">
      4）基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风实现替代手工输出文字的操作。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标通过麦克风获取语音信息。
    </p>
    
    <p style="text-indent:2em;">
      您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息（地理位置）、摄像头（相机）、文件（图片库/存储）、麦克风（语音）的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备“设置”中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。    </p>
    <p>（五）其他用途</p>
    <p style="text-indent:2em;">
      若您提供的信息中含有第三方的个人信息，在向四方云FBT服务平台提供这些个人信息之前，您需确保您已经取得合法的授权。
    </p>
    <p>
      （六）根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意：
    </p>
    <p style="text-indent:2em;">1）与国家安全、国防安全直接相关的；</p>
    <p style="text-indent:2em;"> 2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p style="text-indent:2em;">3）与刑事侦查、起诉、审判和判决执行直接相关的；</p>
    <p style="text-indent:2em;">4）出于维护您或他人的生命、财产重大合法权益但又很难得到您本人同意的；</p>
    <p style="text-indent:2em;">5）所收集的个人信息是您自行向社会公众公开的；</p>
    <p style="text-indent:2em;">
      6）从合法公开披露的信息中收集的个人信息，包括合法的新闻报道、政府信息公开渠道；
    </p>
    <p style="text-indent:2em;">7）根据您的要求签订和履行合同所必需的；</p>
    <p style="text-indent:2em;">
      8）用于维护所提供的服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；
    </p>
    <p style="text-indent:2em;">9）与我们履行法律法规规定的义务相关的；</p>
    <p style="text-indent:2em;">10）法律法规规定的其他情形。</p>
    <h3>2、 Cookie和类似技术的使用</h3>
    <p style="text-indent:2em;">
      为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的登录状态的数据，提升服务/产品质量及优化用户体验。我们不会将 Cookie用于本隐私政策所述目的之外的任何用途。
    </p>
    <h3>3、 我们如何共享、转让、公开披露您的信息</h3>
     <p>（一）共享</p>
    <p style="text-indent:2em;">
      您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的信息披露给第三方：
    </p>
    <p style="text-indent:2em;">
      1）法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    </p>
    <p style="text-indent:2em;">
      2）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
    </p>
    <p style="text-indent:2em;">
      3）在您主动选择情况下共享：您通过四方云FBT服务平台使用的必要信息会共享给您劳动关系所属企业或您使用四方云FBT服务平台所服务的企业或您授权可共享的企业。
    </p>
    <p style="text-indent:2em;">
      4）为您提供服务的目的，您的信息可能会与我们的关联公司共享，我们只会共享必要的个人信息，且受本隐私政策约束。如果关联公司超出本隐私政策使用及处理您的信息，将再次征求您的授权同意。
    </p>
    <p>（二）转让</p>
    <p style="text-indent:2em;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p style="text-indent:2em;">1）事先获得您的明确同意；</p>
    <p style="text-indent:2em;">2）根据法律法规或强制性的行政或司法要求；</p>
    <p style="text-indent:2em;">
      3）转让经去标识化处理的个人信息，且确保数据接收方无法重新识别或者关联个人信息主体；
    </p>
    <p style="text-indent:2em;">
      4）在涉及资产转让、收购、兼并、重组或破产时，如果涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本隐私政策的约束。如果变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明示同意。如果破产且无承接方的，我们将对数据做删除处理。
    </p>
    <p>（三）公开披露 我们仅会在以下情况下，公开披露您的个人信息：</p>
    <p style="text-indent:2em;">1）获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
    <p style="text-indent:2em;">
      2）如果我们确定您出现违反法律法规或严重违反四方云FBT服务平台云平台相关协议及规则的情况，或为保护其他用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或四方云FBT服务平台的相关协议规则披露关于您的个人信息，包括相关违规行为以及四方云FBT服务平台已对您采取的措施。
    </p>
    <p>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
    <p  style="text-indent:2em;">
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p style="text-indent:2em;">1）与国家安全、国防安全有关的；</p>
    <p style="text-indent:2em;">2）与公共安全、公共卫生、重大公共利益有关的；</p>
    <p style="text-indent:2em;">3）与犯罪侦查、起诉、审判和判决执行司法或行政执法有关的；</p>
    <p style="text-indent:2em;">
      4）出于维护您或其他个人的生命、财产重大合法权益但又很难得到您本人同意的；
    </p>
    <p style="text-indent:2em;">5）所收集的个人信息是您自行向社会公众公开的；</p>
    <p style="text-indent:2em;">
      6）从合法公开披露的信息中收集的个人信息，包括合法的新闻报道、政府信息公开渠道。
    </p>
    <p style="text-indent:2em;">
      请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
    </p>
    <h3>4、我们如何存储和保护您的信息</h3>
    <p>（一）技术措施与数据保护措施</p>
    <p style="text-indent:2em;">
      1）我们在保护您的个人信息上将不断努力，争取采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。在您的浏览器与服务器之间交换数据时受SSL（Secure Socket Layer）协议加密保护；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，与相关处理数据的员工签署保密协议，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。另外，我们还会聘请外部独立第三方对我们的信息安全管理体系进行评估。
    </p>
    <p style="text-indent:2em;">
      2）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术各方面多维度提升整个系统的安全性。目前，我们重要信息系统已经通过网络安全等级保护的三级测评。
    </p>
    <p  style="text-indent:2em;">
      3）互联网并非绝对安全的环境，我们强烈建议您不要使用非四方云FBT服务平台推荐的通信方式发送个人信息。在使用四方云FBT服务平台服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，包括联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如果您发现自己的信息发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施。请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
    </p>
    <p style="text-indent:2em;">4）请使用复杂密码，协助我们保证您的账号安全。</p>
    <p>（二）存储期限</p>
    <p style="text-indent:2em;">
      1）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保存您的个人信息。数据存储期限为二十年，除非法律有强制的存留要求。如果我们更改数据存储时间，会另行通知您。在您的个人信息超出保留期间后或您要求注销账户后，我们将删除您的个人信息或匿名化处理，法律法规另有规定的除外。
    </p>
    <p>
      （三）我们在中华人民共和国境内运营收集和产生的个人信息，存储在中国境内，以下情形除外：
    </p>
    <p style="text-indent:2em;">1）法律法规有明确规定；</p>
    <p style="text-indent:2em;">2）获得您的明确授权。</p>
    <p>四）安全事件处理</p>
    <p style="text-indent:2em;">
      在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、信函、电话、推送通知的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <h3>5、 您如何管理您的信息</h3>
    <p>（一）查询、更正或补充您的个人信息</p>
    <p>
      您有权查询、更正或补充您的信息，您可以通过登录四方云FBT服务平台，进入“我的-资料管理”的方式自行进行查询、更正或补充。
    </p>
    <p>（二）删除您的个人信息</p>
    <p style="text-indent:2em;">
      1）一般而言，我们只会在法律法规定或必需且最短的时间内保存您的个人信息。
    </p>
    <p style="text-indent:2em;">
      2）对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以直接进行删除。
    </p>
    <p style="text-indent:2em;">
      3）在以下情形中，您可以登录四方云FBT服务平台，进入“我的-设置-账号注销”，联系四方云FBT服务平台客服或工作人员，向我们提出删除个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外：
    </p>
    <p style="text-indent:2em;">①如果我们处理个人信息的行为违反法律法规；</p>
    <p style="text-indent:2em;">②如果我们收集、使用您的个人信息，却未征得您的同意；</p>
    <p style="text-indent:2em;">③如果我们处理个人信息的行为违反了与您的约定；</p>
    <p style="text-indent:2em;">④如果您不再使用我们的产品或服务，或您注销了账号。</p>
    <p style="text-indent:2em;">
      4）您理解并同意：当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
    </p>
    <p>（三）账户注销</p>
    <p  style="text-indent:2em;">
      您可以通过联系四方云FBT服务平台客服或者工作人员进行账户注销。当您符合约定的账户注销条件并注销某项服务相关账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或对外提供与该账户相关的个人信息，但我们仍需按照监管要求的时间保存您在使用服务期间提供或产生的信息，且在该依法保存的时间内有权机关仍有权依法查询。
    </p>
    <p>（四）获取您的个人信息副本</p>
    <p style="text-indent:2em;">
      您可以联系四方云FBT服务平台客服申请获取您已经提供给四方云FBT服务平台的个人信息副本（包括：基本资料、身份信息）。
    </p>
    <p>（五）约束信息系统自动决策</p>
    <p style="text-indent:2em;">
      在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权联系四方云FBT服务平台客服要求我们做出解释或要求提供适当的救济方式。
    </p>
    <p>（六）响应您的请求</p>
    <p style="text-indent:2em;">
      如果您无法自主查询、更正或删除您的个人信息，或者其中部分功能系统不支持的，您均可以通过本隐私政策下方的联系方式与我们联系。为了保障安全，我们可能需要您提供书面请求或以其他方式标明您的身份，我们将在收到您的反馈并在验证您的身份后15天内答复您的请求。
    </p>
    <p>
      （七）如果您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可联系相应的电话或通过客服，要求删除相应的信息。
    </p>
    <p>
      （八）尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：
    </p>
    <p style="text-indent:2em;">1）与国家安全、国防安全直接相关的；</p>
    <p style="text-indent:2em;">2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p style="text-indent:2em;">3）与刑事侦查、起诉、审判和执行判决直接相关的；</p>
    <p style="text-indent:2em;">4）有充分证据表明您存在主观恶意或滥用权利的；</p>
    <p style="text-indent:2em;">5）响应您的请求将导致其他个人、组织的合法权益受到严重损害的；。</p>
    <p style="text-indent:2em;">
      6）出于维护您或其他个人的生命、财产重大合法权益但又很难得到您本人授权同意的；。
    </p>
    <p style="text-indent:2em;">7）涉及商业秘密的；</p>
    <p style="text-indent:2em;">8）与我们履行法律法规规定的义务相关的。</p>
    <h3>6、  本隐私政策的适用及更新。</h3>
    <p>
      （一）四方云FBT服务平台所有服务均适用本隐私政策，除非相关服务已有独立的隐私权政策。
    </p>
    <p>
      （二）必要情况下，我们可能会对《四方云FBT服务平台隐私政策》进行修订。请您注意，只有在您确认修订后的《四方云FBT服务平台隐私政策》后，我们才会按照修订后的政策收集、使用、处理和存储您的个人信息。您可以选择不同意修订后的《四方云FBT服务平台隐私政策》，但可能导致您无法使用四方云FBT服务平台的全部或部分产品及服务功能。
    </p>
    <p>（三）未经您同意，我们不会限制您按照本隐私政策所应享受的权利。</p>
    <p>（四）发生下列重大变化情形时，我们会适时对本隐私政策进行更新：</p>
    <p style="text-indent:2em;">1.我们的基本情况发生变化，包括兼并、收购、重组引起的所有者变更；</p>
    <p style="text-indent:2em;">2.收集、存储、使用个人信息的范围、目的、规则发生变化；</p>
    <p style="text-indent:2em;">3.对外提供个人信息的对象、范围、目的发生变化；</p>
    <p style="text-indent:2em;">4.您访问和管理个人信息的方式发生变化；</p>
    <p style="text-indent:2em;">5.数据安全能力、信息安全风险发生变化；</p>
    <p  style="text-indent:2em;">6.用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化； </p>
    <p  style="text-indent:2em;">7.其他可能对您的个人信息权益产生重大影响的变化。</p>
    <p>（五）如果您在本隐私政策更新生效后继续使用相关服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。</p>
    <h3>7、如何联系我们</h3>
    <p>
      （一）如果您对本隐私政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，请通过相应的邮箱、客服联系我们，我们会尽快回复，最长不超过15天。
    </p>
    <p>
      （二）如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向四方云FBT服务平台住所地有管辖权的法院提起诉讼来寻求解决方案。
    </p>
    <p>（三）产品运营者基本情况</p>
    <p>产品名称：四方云FBT服务平台</p>
    <p>公司名称：北京传世博润科技有限公司</p>
    <p>
      注册地址：北京市朝阳区利泽西街6号院3号楼10层1001内18
    </p>
    <p>个人信息保护相关负责人联系方式： lianlian@csbr.cn</p>
  </div>
</template>

<script>
export default {};
</script>
